/* eslint-disable @next/next/no-img-element */
import { useState } from 'react';

// components
import { Container } from 'components/Grid';

// styles
import {
  SectionWrapper,
  TitleWrapper,
  AwardsBadges,
  BadgeContainer,
  Badge,
  CTAWrapper,
  Title,
  IntroText,
  MediaWrapper,
  VideoWrapper,
  ClosingIcon,
  Thumbnail,
  Picture,
  PlayingIcon,
  LogoWrapper,
  BrandWrapper,
} from './LogoImageCTA.styles';
import {
  MirandaSecondaryButton,
  MirandaHollowSecondaryButton,
} from '@/components/Button/BaseButton';

//vendor
import ReactPlayer from 'react-player/lazy';

//logos
import { LogoShipperGuide } from './Logos';

//icons
import { IconClose } from '@loadsmart/icons';
import IconPlay from '@/public/images/carrier-tms/video-hero/icon-play.svg';

export function LogoImageCTA() {
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <SectionWrapper>
      <Container>
        <TitleWrapper>
          <BrandWrapper>
            <LogoWrapper>
              <LogoShipperGuide />
            </LogoWrapper>{' '}
            &nbsp;TMS
          </BrandWrapper>
          <Title>
            Your multimodal freight procurement
            <br /> &amp; execution tool.
          </Title>
          <IntroText>
            Plan, procure and execute freight with the next generation
            Transportation Management System. Real-time spot and contract price
            comparison. Access instantly bookable spot rates in multiple modes
            including FTL, LTL, vLTL and Drayage. Manage your own carrier
            networks with historical land performance, tendering, tracking and
            data insights.
          </IntroText>

          <AwardsBadges>
            <BadgeContainer>
              <Badge
                width="92"
                height="106"
                src="/images/awards/g2-logos-2025/FreightManagement_EasiestSetup_Mid-Market.svg"
                alt="Easiest Setup Mid-Market"
              />
            </BadgeContainer>
            <BadgeContainer>
              <Badge
                width="92"
                height="106"
                src="/images/awards/g2-logos-2025/TransportationManagementSystemsTMS_BestSupport.svg"
                alt="Best Support"
              />
            </BadgeContainer>
            <BadgeContainer>
              <Badge
                width="92"
                height="106"
                src="/images/awards/g2-logos-2025/TransportationManagementSystemsTMS_EasiestToDoBusinessWith.svg"
                alt="Easiest to do Business With"
              />
            </BadgeContainer>
            <BadgeContainer>
              <Badge
                width="92"
                height="106"
                src="/images/awards/g2-logos-2025/TransportationManagementSystemsTMS_Leader.svg"
                alt="Grid Leader"
              />
            </BadgeContainer>
          </AwardsBadges>
        </TitleWrapper>
        <MediaWrapper>
          <VideoWrapper className={playVideo ? `active` : ``}>
            <ClosingIcon
              onClick={() => {
                setPlayVideo(false);
              }}
            >
              <IconClose />
            </ClosingIcon>
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=YVAveKOVy2Y?rel=0"
              controls
              playing={playVideo}
              width="100%"
              height="100%"
            />
          </VideoWrapper>
          <Thumbnail
            className={playVideo ? `inactive` : ``}
            onClick={() => {
              setPlayVideo(true);
            }}
            role="button"
          >
            <Picture>
              <source
                srcSet={`/images/shipperguide/image-cta/shipperguide-dashboard@2x.png`}
                media="(min-width: 1920px)"
              />
              <source
                srcSet={`/images/shipperguide/image-cta/shipperguide-dashboard@2x.png`}
                media="(min-width: 1024px)"
              />
              <source
                srcSet={`/images/shipperguide/image-cta/shipperguide-dashboard@2x.png`}
                media="(min-width: 768px)"
              />
              <img
                srcSet={`/images/shipperguide/image-cta/shipperguide-dashboard@2x.png`}
                alt="Shipper Guide dashboard screen"
                width="677"
                height="520"
              />
            </Picture>
            <PlayingIcon>
              <IconPlay />
            </PlayingIcon>
          </Thumbnail>
        </MediaWrapper>
        <CTAWrapper>
          <MirandaSecondaryButton as="a" href="/shipper/shipperguide/">
            Know More About ShipperGuide TMS
          </MirandaSecondaryButton>
          <MirandaHollowSecondaryButton
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://app.shipper.guide/signup"
          >
            Start Quoting For Free Now
          </MirandaHollowSecondaryButton>
        </CTAWrapper>
      </Container>
    </SectionWrapper>
  );
}
